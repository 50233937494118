import { get } from 'lodash';
import { zhTW, enUS, vi, id as Indonesia } from 'date-fns/locale';

import { localStorageAvailable, localStorageGetItem } from 'src/utils/storage-available';
import uuidv4 from 'src/utils/uuidv4';

// 將 employeeId 轉成 員工姓名
export const getEmployeeNameById = (employees, empId) => {
  if (empId === null) return '';
  const employee = employees.find((emp) => emp.id === empId);

  if (employee) {
    return employee.name;
  }
  return '無';
};

export const getEmployeeEmailById = (employees, empId) => {
  if (empId === null) return '';
  const employee = employees.find((emp) => emp.id === empId);

  if (employee) {
    return employee.email;
  }
  return '';
};

// 將 companyId 轉成 中文公司名
export const getCompanyNameById = (companys, companyId) => {
  if (companyId === null) return '';
  const companyItem = companys.find((company) => company.id === companyId);

  if (companyItem) {
    return companyItem.name;
  }
  return '無';
};

// 將 employeeId 轉成 員工姓名
export const getDepartmentNameById = (departments, departmentId) => {
  if (departmentId === null) return '';
  const department = departments.find((dept) => dept.id === departmentId);

  if (department) {
    return department.name;
  }
  return '無';
};

export const getLeaveNameById = (leavetypes, leavetypId) => {
  if (leavetypId === null) return '';
  const leavetype = leavetypes.find((type) => type.id === leavetypId);

  if (leavetype) {
    return leavetype.leaveType;
  }
  return '無';
};

export const getNameWithDepartment = (employees, departments, empId) => {
  if (empId === null) return '';
  const employee = employees.find((emp) => emp.id === empId);

  if (employee) {
    const department = departments.find((dept) => dept.id === employee.department);
    return `${employee.name} (${department.name})`;
  }
  return '無';
};

export const getNameWithCompanyDepartPosition = (general, empId) => {
  if (empId === null) return '';
  const employee = general.employees.find((emp) => emp.id === empId);
  if (employee) {
    const department = getDepartmentNameById(general.departments, employee.department);
    const company = getCompanyNameById(general.companies, employee.companyId);
    const position = employee.position;
    return `${employee.name} (${company} - ${department} - ${position})`;
  }
  return '無';
};

export const getNameWithCompanyDepartPositionObj = (general, empId) => {
  if (empId === null) return {};
  const employee = general.employees.find((emp) => emp.id === empId);
  if (employee) {
    const department = getDepartmentNameById(general.departments, employee.department);
    const company = getCompanyNameById(general.companies, employee.companyId);
    const position = employee.position;
    return {
      name: employee.name,
      company,
      department,
      position,
    };
  }
  return {};
};

export const translateApprovedStatus = (statusCode) => {
  const statusMap = {
    publish: '發佈',
    approved: '核准',
    pending: '待辦',
    rejected: '駁回',
    draft: '草稿',
    delete: '刪除',
  };

  return statusMap[statusCode] || '未知';
};

export const translateApprovedStatusByLocale = (statusCode, t) => {
  const statusMap = {
    publish: t('publish'),
    approved: t('approved'),
    pending: t('pending'),
    rejected: t('rejected'),
    draft: t('draft'),
    delete: t('delete'),
  };

  return statusMap[statusCode] || t('unknown');
};

export const getCurrentDateString = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份是從0開始的
  return `${year}-${month}`;
};

export function getAllDatesInMonth(yearMonthStr, limitDays = null) {
  const [year, month] = yearMonthStr.split('-').map(Number);

  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDay = Math.floor(
    (nextMonthFirstDay - new Date(year, month - 1, 1)) / (1000 * 60 * 60 * 24)
  );

  let allDates = Array.from(
    { length: lastDay },
    (_, i) => `${year}-${String(month).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`
  );

  const today = new Date();
  if (year === today.getFullYear() && month === today.getMonth() + 1) {
    allDates = allDates.filter((date) => Number(date.split('-').pop()) <= today.getDate());
  }

  // 如果設置了 limitDays，則只返回最後 limitDays 天
  if (limitDays !== null) {
    allDates = allDates.slice(-limitDays);
  }

  return allDates;
}

export function getAllWorkingDatesInMonth(yearMonthStr, specialDays, limitDays = null) {
  const [year, month] = yearMonthStr.split('-').map(Number);

  const nextMonthFirstDay = new Date(year, month, 1);
  const lastDay = (nextMonthFirstDay - new Date(year, month - 1, 1)) / (1000 * 60 * 60 * 24);

  let allDates = Array.from(
    { length: lastDay },
    (_, i) => `${year}-${String(month).padStart(2, '0')}-${String(i + 1).padStart(2, '0')}`
  );

  const today = new Date();
  if (year === today.getFullYear() && month === today.getMonth() + 1) {
    allDates = allDates.filter((date) => Number(date.split('-').pop()) <= today.getDate());
  }

  // 轉換特殊日子的日期為字符串格式
  const specialDayMap = {};
  specialDays.forEach(({ id, isWorkday }) => {
    specialDayMap[id] = isWorkday;
  });

  // 篩選工作日
  let workingDays = allDates.filter((date) => {
    const dateId = date;
    if (dateId in specialDayMap) {
      return specialDayMap[dateId]; // 是特殊工作日或特殊休息日
    }
    const dayOfWeek = new Date(date).getDay();
    return dayOfWeek !== 0 && dayOfWeek !== 6; // 不是週末
  });

  // 判斷是否需要限制回傳的工作日數量
  if (limitDays !== null) {
    workingDays = workingDays.slice(-limitDays);
  }

  return workingDays;
}

export const calculateServiceDetails = (joinDate) => {
  if (!joinDate) {
    return [0, 0, 0];
  }

  const joinDateObj = new Date(`${joinDate}T00:00:00`);
  // const currentDate = new Date();
  const fDate = new Date();
  const currentDate = new Date(fDate.getFullYear(), fDate.getMonth(), fDate.getDate());

  let yearsOfService = currentDate.getFullYear() - joinDateObj.getFullYear();

  const pastJoinDateThisYear =
    currentDate.getMonth() > joinDateObj.getMonth() ||
    (currentDate.getMonth() === joinDateObj.getMonth() &&
      currentDate.getDate() >= joinDateObj.getDate());

  // 修正年資的計算
  if (!pastJoinDateThisYear) {
    yearsOfService -= 1;
  }

  // 儲存的年、月、日
  const year = currentDate.getFullYear() + (pastJoinDateThisYear ? 1 : 0);
  const month = joinDateObj.getMonth();
  const day = joinDateObj.getDate();

  // 轉換月份和日期為兩位數格式
  const monthStr = (month + 1).toString().padStart(2, '0'); // 月份+1，因為getMonth()從0開始計數
  const dayStr = day.toString().padStart(2, '0');

  // 組合成 ISO 8601 標準的日期格式字符串
  const dateString = `${year}-${monthStr}-${dayStr}T00:00:00`;

  // 創建新的 Date 物件
  const nextJoinDate = new Date(dateString);

  const timeUntilNextJoinDate = nextJoinDate - currentDate;
  const daysUntilNextJoinDate = Math.ceil(timeUntilNextJoinDate / (1000 * 60 * 60 * 24));

  const lastJoinDate = new Date(currentDate);
  lastJoinDate.setFullYear(currentDate.getFullYear() - (pastJoinDateThisYear ? 0 : 1));
  lastJoinDate.setMonth(joinDateObj.getMonth());
  lastJoinDate.setDate(joinDateObj.getDate());

  const totalDaysForThisServiceYear = (nextJoinDate - lastJoinDate) / (1000 * 60 * 60 * 24);
  const daysSinceLastJoinDate = (currentDate - lastJoinDate) / (1000 * 60 * 60 * 24);

  // 計算年資到小數點第一位，第二位之後無條件捨去
  const totalYearsOfServiceDecimal =
    Math.floor((yearsOfService + daysSinceLastJoinDate / totalDaysForThisServiceYear) * 10) / 10;

  const percentageUntilNextJoinDate = parseFloat(
    ((daysSinceLastJoinDate / totalDaysForThisServiceYear) * 100).toFixed(2)
  );

  // daysUntilNextJoinDate 有問題
  return [totalYearsOfServiceDecimal, daysUntilNextJoinDate, percentageUntilNextJoinDate];
};

// 適用勞動基準法之事業單位，依勞動基準法第38條第1項規定，
// 勞工在同一雇主或事業單位，繼續工作滿一定期間者，應依下列規定給予特別休假：
// (1)6個月以上1年未滿者，3日。
// (2)1年以上2年未滿者，7日。
// (3)2年以上3年未滿者，10日。
// (4)3年以上5年未滿者，每年14日。
// (5)5年以上10年未滿者，每年15日。
// (6)10年以上者，每1年加給1日，加至30日為止。
export const calculateNewLeaveDays = (joinDateStr) => {
  if (!joinDateStr) {
    return [0, 0, 0, 0];
  }

  const joinDate = new Date(joinDateStr);
  const leaveDays = [
    {
      date: new Date(joinDate.getFullYear(), joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 0,
    },
    {
      date: new Date(joinDate.getFullYear(), joinDate.getMonth(), joinDate.getDate() + 183),
      leaveDays: 3,
    },
    {
      date: new Date(joinDate.getFullYear() + 1, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 7,
    },
    {
      date: new Date(joinDate.getFullYear() + 2, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 10,
    },
    {
      date: new Date(joinDate.getFullYear() + 3, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 14,
    },
    {
      date: new Date(joinDate.getFullYear() + 5, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 15,
    },
    {
      date: new Date(joinDate.getFullYear() + 10, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 16,
    },
    {
      date: new Date(joinDate.getFullYear() + 11, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 17,
    },
    {
      date: new Date(joinDate.getFullYear() + 12, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 18,
    },
    {
      date: new Date(joinDate.getFullYear() + 13, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 19,
    },
    {
      date: new Date(joinDate.getFullYear() + 14, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 20,
    },
    {
      date: new Date(joinDate.getFullYear() + 15, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 21,
    },
    {
      date: new Date(joinDate.getFullYear() + 16, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 22,
    },
    {
      date: new Date(joinDate.getFullYear() + 17, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 23,
    },
    {
      date: new Date(joinDate.getFullYear() + 18, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 24,
    },
    {
      date: new Date(joinDate.getFullYear() + 19, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 25,
    },
    {
      date: new Date(joinDate.getFullYear() + 20, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 26,
    },
    {
      date: new Date(joinDate.getFullYear() + 21, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 27,
    },
    {
      date: new Date(joinDate.getFullYear() + 22, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 28,
    },
    {
      date: new Date(joinDate.getFullYear() + 23, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 29,
    },
    {
      date: new Date(joinDate.getFullYear() + 24, joinDate.getMonth(), joinDate.getDate()),
      leaveDays: 30,
    },
  ];

  // console.log('leaveDays', leaveDays);

  // const currentDate = new Date(); // 假設這是今天的日期，可根據需要調整
  const fDate = new Date();
  const currentDate = new Date(fDate.getFullYear(), fDate.getMonth(), fDate.getDate());

  let currentLeaveDays = 0;
  let nextLeaveDays = 0;
  let daysUntilNextLeaveDays = 0;
  let percentageUntilNextLeaveDays = 0;

  for (let i = 0; i < leaveDays.length - 1; i += 1) {
    if (currentDate >= leaveDays[i].date && currentDate < leaveDays[i + 1].date) {
      currentLeaveDays = leaveDays[i].leaveDays;
      nextLeaveDays = leaveDays[i + 1].leaveDays;
      daysUntilNextLeaveDays = Math.round(
        (leaveDays[i + 1].date - currentDate) / (1000 * 60 * 60 * 24)
      );
      const totalDaysInCurrentPeriod = Math.round(
        (leaveDays[i + 1].date - leaveDays[i].date) / (1000 * 60 * 60 * 24)
      );
      const daysFromIndexToCurrentDate = Math.round(
        (currentDate - leaveDays[i].date) / (1000 * 60 * 60 * 24)
      );
      percentageUntilNextLeaveDays = (daysFromIndexToCurrentDate / totalDaysInCurrentPeriod) * 100;
      break;
    }
  }

  // 檢查是否已經達到或超過最後一個階段
  if (currentDate >= leaveDays[leaveDays.length - 1].date) {
    currentLeaveDays = leaveDays[leaveDays.length - 1].leaveDays; // 設定為最後一階段的特休日
    nextLeaveDays = 0; // 沒有下一等級的特休日
    daysUntilNextLeaveDays = 0; // 沒有下一個階段
    percentageUntilNextLeaveDays = 100; // 已達到最大特休日，百分比設為100%
  }

  return [
    currentLeaveDays,
    nextLeaveDays,
    daysUntilNextLeaveDays,
    percentageUntilNextLeaveDays.toFixed(2),
  ];
};

export const getDeviceInfo = async () => {
  try {
    const ua = navigator.userAgent;
    const URL = `https://asia-east1-yiqun-portal.cloudfunctions.net/get_time_and_ip`;
    const ipResponse = await fetch(URL);
    const ipData = await ipResponse.json();
    const ip = ipData && ipData.client_ip ? ipData.client_ip : 'unknown';

    // 嘗試從 localStorage 中獲取 uuid
    let uuid = localStorageGetItem('uuid');

    // 如果 uuid 不存在，創建一個新的，並存儲到 localStorage
    if (!uuid) {
      uuid = uuidv4(); // 調用您的 uuidv4 函數
      if (localStorageAvailable()) {
        localStorage.setItem('uuid', uuid);
      }
    }

    // 返回裝置資訊和 uuid
    return {
      userAgent: ua,
      ip,
      uuid, // 包含 uuid
    };
  } catch (error) {
    console.error('無法獲取裝置資訊', error);
    return {
      userAgent: 'unknown',
      ip: 'unknown',
      error: error.message,
    };
  }
};

/**
 * generateYearMonthOptions
 *
 * 這個函數用於生成一個日期選項的列表，從特定的起始日期（2023年9月）到當前日期的每個月份。
 * 每個選項都以 `{ value: 'YYYY-MM', label: 'YYYY-MM' }` 的格式表示，其中 `YYYY-MM` 代表年份和月份。
 *
 * @returns {Object[]} 返回一個物件陣列，每個物件代表一個月份，具有 `value` 和 `label` 兩個屬性。
 *                     這些物件按照從最近的月份到起始月份（2023年9月）的順序排列。
 *
 * 範例:
 * 如果今天是 `2023-12-01`，那麼這個函數將返回：
 * [
 *   { value: '2023-12', label: '2023-12' },
 *   { value: '2023-11', label: '2023-11' },
 *   { value: '2023-10', label: '2023-10' },
 *   { value: '2023-09', label: '2023-09' },
 * ]
 *
 * 使用說明:
 * 無需傳入任何參數即可調用此函數。函數將根據當前系統日期自動計算需要返回的月份範圍。
 * 適用於需要根據當前日期動態生成日期選項的場景，如在日期選擇器或報表篩選中。
 */
// export function generateYearMonthOptions() {
//   const startDate = new Date('2023-09-01');
//   const currentDate = new Date();
//   const options = [];

//   for (let year = currentDate.getFullYear(); year >= startDate.getFullYear(); year -= 1) {
//     let startMonth = 12;
//     let endMonth = 1;

//     if (year === currentDate.getFullYear()) {
//       startMonth = currentDate.getMonth() + 1;
//     }
//     if (year === startDate.getFullYear()) {
//       endMonth = startDate.getMonth() + 1;
//     }

//     for (let month = startMonth; month >= endMonth; month -= 1) {
//       const formattedMonth = month < 10 ? `0${month}` : month;
//       options.push({ value: `${year}-${formattedMonth}`, label: `${year}-${formattedMonth}` });
//     }
//   }

//   return options;
// }
export function generateYearMonthOptions(includeAll = false) {
  const startDate = new Date('2023-09-01');
  const currentDate = new Date();
  const options = [];

  // 如果 includeAll 為 true，則在陣列最前面加入 "全部" 選項
  if (includeAll) {
    options.push({ value: 'all', label: '全部' });
  }

  for (let year = currentDate.getFullYear(); year >= startDate.getFullYear(); year -= 1) {
    let startMonth = 12;
    let endMonth = 1;

    if (year === currentDate.getFullYear()) {
      startMonth = currentDate.getMonth() + 1;
    }
    if (year === startDate.getFullYear()) {
      endMonth = startDate.getMonth() + 1;
    }

    for (let month = startMonth; month >= endMonth; month -= 1) {
      const formattedMonth = month < 10 ? `0${month}` : month;
      options.push({ value: `${year}-${formattedMonth}`, label: `${year}-${formattedMonth}` });
    }
  }

  return options;
}

export function maskEmail(email) {
  if (!email) {
    return '';
  }
  const [username, domain] = email.split('@');
  const maskedUsername = username.slice(0, 2) + '*'.repeat(username.length - 2);
  return `${maskedUsername}@${domain}`;
}

export function generateMonthStrings() {
  const now = new Date();

  // 上個月
  const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
  const lastMonthStr = `${lastMonth.getFullYear()}-${String(lastMonth.getMonth() + 1).padStart(
    2,
    '0'
  )}`;

  // 本月
  const thisMonthStr = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;

  // 下個月
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const nextMonthStr = `${nextMonth.getFullYear()}-${String(nextMonth.getMonth() + 1).padStart(
    2,
    '0'
  )}`;

  return [lastMonthStr, thisMonthStr, nextMonthStr];
}

/**
 * 產生月份字串陣列
 *
 * @returns 月份字串陣列
 */
export function generateHalfYearMonthStrings() {
  const now = new Date();

  // 本月
  const thisMonthStr = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;

  // 產生前半年的月份
  const halfYearAgo = new Date(now.getFullYear(), now.getMonth() - 6, 1);
  const halfYearAgoStr = `${halfYearAgo.getFullYear()}-${String(
    halfYearAgo.getMonth() + 1
  ).padStart(2, '0')}`;

  const monthStrings = [];
  for (let i = -6; i <= 7; i += 1) {
    const month = new Date(halfYearAgo.getFullYear(), halfYearAgo.getMonth() + i, 1);
    const monthStr = `${month.getFullYear()}-${String(month.getMonth() + 1).padStart(2, '0')}`;
    monthStrings.push(monthStr);
  }

  return monthStrings;
}

/**
 * 獲取邊界日期的函數
 * @returns {Object} 包含開始日期和結束日期的物件
 * @example
 * const boundaryDates = getBoundaryDates();
 * console.log(boundaryDates); // { startDate: ..., endDate: ... }
 */
export function getBoundaryDates() {
  // 獲取當前日期
  const currentDate = new Date();

  // 計算前一個月份的第一天
  const firstDayOfLastMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);

  // 計算下一個月份的最後一天
  const lastDayOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);

  // 設定時間為開始的 00:00:00 和結束的 23:59:59
  const firstDayOfMonthStart = new Date(firstDayOfLastMonth.setHours(0, 0, 0, 0));
  const lastDayOfMonthEnd = new Date(lastDayOfNextMonth.setHours(23, 59, 59, 999));

  return {
    firstDayOfMonthStart,
    lastDayOfMonthEnd,
  };
}

export function checkKeyResultExists(okrList, id, parentObjectiveId) {
  // 檢查 id 或 parentObjectiveId 是否為空白
  if (!id.trim() || !parentObjectiveId.trim()) {
    return false;
  }

  // 尋找 id 等於 parentObjectiveId 的項目
  const parentObjective = okrList.find((okr) => okr.id === parentObjectiveId);

  // 如果找到了對應的目標項目
  if (parentObjective) {
    // 在該目標項目的 keyResults 陣列中尋找 id 等於輸入 id 的項目
    const keyResultExists = parentObjective.keyResults.some((keyResult) => keyResult.id === id);
    // 如果找到，返回 false；否則，返回 true
    return !keyResultExists;
  }

  // 如果沒有找到對應的目標項目，表示輸入的 parentObjectiveId 無效，也返回 true
  return true;
}

/**
 * 根據指定的 ID 在 OKR 列表中尋找對應的 OKR。
 * @param {Array} okrList - OKR 列表
 * @param {string} id - 要尋找的 OKR 的 ID
 * @returns {Object|null} - 找到的 OKR 物件，如果找不到則返回 null
 */
export function findOkrById(okrList, id) {
  let foundOkr = null;

  okrList.some((okr) => {
    if (okr.id === id) {
      foundOkr = okr;
      return true;
    }
    const foundInChildren = findOkrById(okr.children || [], id);
    if (foundInChildren) {
      foundOkr = foundInChildren;
      return true;
    }
    return false;
  });

  return foundOkr;
}

/**
 * 找到給定 OKR 和 OKR 地圖的頂層 OKR（目標和關鍵結果）。
 * @param {Object} okr - 要找到頂層的 OKR。
 * @param {Object} okrMap - OKR 地圖，其中鍵是 OKR 的 ID，值是 OKR 對象。
 * @returns {Object} - 頂層 OKR 對象。
 */
export function findTopLevelOkr(okr, okrMap) {
  let currentOkr = okr;
  while (currentOkr.parentObjectiveId && okrMap[currentOkr.parentObjectiveId]) {
    currentOkr = okrMap[currentOkr.parentObjectiveId];
  }
  return currentOkr;
}

export const getUserLocale = (locale) => {
  if (locale === 'vi') {
    return vi;
  }
  if (locale === 'enUS') {
    return enUS;
  }
  if (locale === 'id') {
    return Indonesia;
  }
  return zhTW;
};

export const leaveTypes = [
  {
    id: 'annual_leave',
    description: 'annual_leave_description',
    leaveType: 'annual_leave_type',
    index: 1,
  },
  {
    id: 'sick_leave',
    index: 2,
    leaveType: 'sick_leave_type',
    description: 'sick_leave_description',
  },
  {
    leaveType: 'menstrual_leave_type',
    description: 'menstrual_leave_description',
    index: 3,
    id: 'menstrual_leave',
  },
  {
    leaveType: 'personal_leave_type',
    id: 'personal_leave',
    description: 'personal_leave_description',
    index: 3,
  },
  {
    index: 4,
    id: 'marriage_leave',
    leaveType: 'marriage_leave_type',
    description: 'marriage_leave_description',
  },
  {
    index: 5,
    leaveType: 'bereavement_leave_type',
    description: 'bereavement_leave_description',
    id: 'bereavement_leave',
  },
  {
    description: 'maternity_leave_description',
    leaveType: 'maternity_leave_type',
    index: 6,
    id: 'maternity_leave',
  },
  {
    index: 7,
    id: 'paternity_leave',
    description: 'paternity_leave_description',
    leaveType: 'paternity_leave_type',
  },
  {
    description: 'prenatal_check_up_leave_description',
    leaveType: 'prenatal_check_up_leave_type',
    id: 'prenatal_check_up_leave',
    index: 8,
  },
  {
    leaveType: 'occupational_injury_leave_type',
    index: 9,
    id: 'occupational_injury_leave',
    description: 'occupational_injury_leave_description',
  },
  {
    id: 'childcare_leave',
    description: 'childcare_leave_description',
    leaveType: 'childcare_leave_type',
    index: 10,
  },
  {
    id: 'unpaid_leave',
    description: 'unpaid_leave_description',
    leaveType: 'unpaid_leave_type',
    index: 11,
  },
  {
    description: 'other_leaves_description',
    index: 20,
    leaveType: 'other_leaves_type',
    id: 'other_leaves',
  },
];
