import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// locales
import { useLocales } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';

const ReloadPageButton = () => {

  const { t } = useLocales();


  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <Tooltip title={t('reload')} placement="top" arrow>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        aria-label={t('reload')}
        onClick={() => {
          reloadPage();
        }}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Iconify icon="solar:refresh-circle-bold-duotone" width={24} />
      </IconButton>
    </Tooltip>
  );
};

export default ReloadPageButton;
