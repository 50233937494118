import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge, { badgeClasses } from '@mui/material/Badge';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// locales
import { useLocales } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import AppClockRecordsDrawer from 'src/sections/overview/app/app-clock-records-drawer';

// ----------------------------------------------------------------------

export default function ClockRecordButton({ sx }) {
  const { t } = useLocales();

  const details = useBoolean();
  return (
    <>
      <Tooltip title={t('clock_today')} placement="top" arrow>
        <IconButton
          component={m.button}
          whileTap="tap"
          whileHover="hover"
          variants={varHover(1.05)}
          aria-label={t('clock_today')}
          onClick={details.onToggle}
          sx={{
            width: 40,
            height: 40,
          }}
        >
          <Iconify icon="solar:sticker-smile-square-bold-duotone" width={24} />
        </IconButton>
      </Tooltip>
      <AppClockRecordsDrawer open={details.value} onClose={details.onFalse} />
    </>
  );
}

ClockRecordButton.propTypes = {
  sx: PropTypes.object,
};
