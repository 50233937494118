import merge from 'lodash/merge';

import {
  enUS as enUSAdapter,
  zhCN as zhCNAdapter,
  zhTW as zhTWAdapter,
  vi as viVNAdapter,
  id as idAdapter, // 引入印尼語
} from 'date-fns/locale';
// core
import {
  enUS as enUSCore,
  zhCN as zhCNCore,
  zhTW as zhTWCore,
  viVN as viVNCore,
  idID as idCore, // 引入印尼語
} from '@mui/material/locale';
// date-pickers
import {
  enUS as enUSDate,
  zhCN as zhCNDate,
  zhHK as zhTWDate,
  viVN as viVNDate,
  // idID as idDate, // 引入印尼語
} from '@mui/x-date-pickers/locales';
// data-grid
import {
  enUS as enUSDataGrid,
  zhCN as zhCNDataGrid,
  zhTW as zhTWDataGrid,
  viVN as viVNDataGrid,
  // idID as idDataGrid, // 引入印尼語
} from '@mui/x-data-grid';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: '繁體中文',
    value: 'tw',
    systemValue: merge(zhTWDate, zhTWDataGrid, zhTWCore),
    adapterLocale: zhTWAdapter,
    icon: 'flagpack:tw',
  },
  {
    label: 'English',
    value: 'en',
    systemValue: merge(enUSDate, enUSDataGrid, enUSCore),
    adapterLocale: enUSAdapter,
    icon: 'flagpack:us',
  },
  {
    label: 'Bahasa Indonesia',
    value: 'id',
    systemValue: merge(enUSDate, enUSDataGrid, idCore),
    adapterLocale: idAdapter,
    icon: 'flagpack:id',
  },
  // {
  //   label: 'French',
  //   value: 'fr',
  //   systemValue: merge(frFRDate, frFRDataGrid, frFRCore),
  //   adapterLocale: frFRAdapter,
  //   icon: 'flagpack:fr',
  // },
  {
    label: 'Tiếng Việt',
    value: 'vi',
    systemValue: merge(viVNDate, viVNDataGrid, viVNCore),
    adapterLocale: viVNAdapter,
    icon: 'flagpack:vn',
  },
  // {
  //   label: 'Chinese',
  //   value: 'cn',
  //   systemValue: merge(zhCNDate, zhCNDataGrid, zhCNCore),
  //   adapterLocale: zhCNAdapter,
  //   icon: 'flagpack:cn',
  // },

  // {
  //   label: 'Arabic',
  //   value: 'ar',
  //   systemValue: merge(arSDDataGrid, arSACore),
  //   adapterLocale: arSAAdapter,
  //   icon: 'flagpack:sa',
  // },
];

export const defaultLang = allLangs[0]; // Taiwan

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
