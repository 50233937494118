import useSWR, { mutate } from 'swr';
import { useMemo, useCallback } from 'react';
import {
  fetchOvertimesByEmployee,
  fetchOvertimeByDocId,
  fetchOvertimesByReviewer,
  fetchLeavesByEmployee,
  fetchLeavesByReviewer,
  fetchAttendanceByEmployee,
  fetchTimesheetsByEmployee,
  fetchTimesheetByDocId,
  fetchTimesheetsByReviewer,
  fetchLeaves,
  fetchAllOvertimes,
  fetchAllTimesheets,
  fetchHrsystemByEmployee,
  fetchHrsystemAttendance,
  fetchClockRecordsByDay,
  endpoints,
  optionSWRAllFalse,
  optionSWRFocusFalse,
  optionSWRIfStaleFalse,
} from 'src/utils/firestore';

export function useGetLeavesByEmployeeId(employeeId) {
  const URL = `${endpoints.leave.list}?employeeId=${employeeId}`;

  const fetcherWithEmployeeId = async (...args) => fetchLeavesByEmployee(employeeId, ...args);

  const { data, error, isValidating } = useSWR(URL, fetcherWithEmployeeId, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      leaves: data?.leaves || [],
      isLoading: !error && !data,
      leavesError: error,
      leavesValidating: isValidating,
      leavesEmpty: data?.leaves && !data?.leaves.length,
    }),
    [data, error, isValidating]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetLeavesByReviewer(employeeId) {
  const URL = endpoints.leave.review.list;

  const fetcherWithReviewer = async (...args) => fetchLeavesByReviewer(employeeId, ...args);

  const { data, error, isValidating } = useSWR(URL, fetcherWithReviewer, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      leaves: data?.leaves || [],
      isLoading: !error && !data,
      leavesError: error,
      leavesValidating: isValidating,
      leavesEmpty: data?.leaves && !data?.leaves.length,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

export function useGetAllLeaves() {
  const URL = `${endpoints.leave.list}?employeeId=all`;

  // console.log('useGet');

  const fetcher = async (...args) => fetchLeaves(...args);

  const { data, error, isValidating } = useSWR(URL, fetcher, optionSWRAllFalse);

  const memoizedValue = useMemo(
    () => ({
      leaves: data?.leaves || [],
      isLoading: !error && !data,
      leavesError: error,
      leavesValidating: isValidating,
      leavesEmpty: data?.leaves && !data?.leaves.length,
    }),
    [data, error, isValidating]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetAttendanceByEmployeeId(docId, employeeId) {
  const URL = `${endpoints.clock.list}?employeeId=${docId}&${employeeId}`;
  // console.log(URL);

  const fetcherWithEmployeeId = async (...args) =>
    fetchAttendanceByEmployee(docId, employeeId, ...args);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherWithEmployeeId);

  const memoizedValue = useMemo(
    () => ({
      attendance: data?.attendances || [],
      attendanceisLoading: isLoading,
      attendanceError: error,
      attendanceValidating: isValidating,
      attendanceEmpty: data?.attendances && !data?.attendances.length,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetTimesheetsByEmployeeId(employeeId) {
  const URL = `timesheets/${employeeId}`; // 假設您將此 URL 用於 SWR 作為識別符

  const fetcherWithEmployeeId = async (...args) => fetchTimesheetsByEmployee(employeeId, ...args);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherWithEmployeeId, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      timesheets: data?.timesheets || [],
      isLoading,
      timesheetsError: error,
      timesheetsValidating: isValidating,
      timesheetsEmpty: data?.timesheets && !data?.timesheets.length,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetAllTimesheets() {
  const URL = `timesheets/all`; // 假設您將此 URL 用於 SWR 作為識別符

  const fetcher = async (...args) => fetchAllTimesheets(...args);

  console.log('useGetAllTimesheets');

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, optionSWRAllFalse);

  const memoizedValue = useMemo(
    () => ({
      timesheets: data?.timesheets || [],
      isLoading,
      timesheetsError: error,
      timesheetsValidating: isValidating,
      timesheetsEmpty: data?.timesheets && !data?.timesheets.length,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetTimesheetByDocId(docId) {
  const URL = `timesheets/${docId}`; // 假設您將此 URL 用於 SWR 作為識別符

  const fetcherWithDocId = async (...args) => fetchTimesheetByDocId(docId, ...args);

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcherWithDocId);

  const memoizedValue = useMemo(
    () => ({
      timesheet: data?.timesheet || null,
      isLoading,
      timesheetError: error,
      timesheetValidating: isValidating,
      timesheetEmpty: !data?.timesheet,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetTimesheetByReviewer(employeeId) {
  const URL = endpoints.timesheet.review.list;

  const fetcherWithReviewer = async (...args) => fetchTimesheetsByReviewer(employeeId, ...args);

  const { data, error, isValidating } = useSWR(URL, fetcherWithReviewer, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      timesheets: data?.timesheets || [],
      isLoading: !error && !data,
      timesheetsError: error,
      timesheetsValidating: isValidating,
      timesheetsEmpty: data?.timesheets && !data?.timesheets.length,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

export function useGetAllOvertimes() {
  const URL = `${endpoints.overtime.list}?employeeId=all`;

  const fetcher = async (...args) => fetchAllOvertimes(...args);

  const { data, error, isValidating } = useSWR(URL, fetcher, optionSWRAllFalse);

  const memoizedValue = useMemo(
    () => ({
      overtimes: data?.overtimes || [],
      isLoading: !error && !data,
      overtimesError: error,
      overtimesValidating: isValidating,
      overtimesEmpty: data?.overtimes && !data?.overtimes.length,
    }),
    [data, error, isValidating]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetOvertimesByEmployeeId(employeeId) {
  const URL = `${endpoints.overtime.list}?employeeId=${employeeId}`;

  const fetcherWithEmployeeId = async (...args) => fetchOvertimesByEmployee(employeeId, ...args);

  const { data, error, isValidating } = useSWR(URL, fetcherWithEmployeeId, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      overtimes: data?.overtimes || [],
      isLoading: !error && !data,
      overtimesError: error,
      overtimesValidating: isValidating,
      overtimesEmpty: data?.overtimes && !data?.overtimes.length,
    }),
    [data, error, isValidating]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetOvertimeByDocId(docId) {
  const URL = `overtimes/${docId}`; // 假設您將此 URL 用於 SWR 作為識別符

  const fetcherWithDocId = async (...args) => fetchOvertimeByDocId(docId, ...args);

  const { data, error, isValidating, isLoading } = useSWR(URL, fetcherWithDocId);

  const memoizedValue = useMemo(
    () => ({
      overtime: data?.overtime || null,
      isLoading,
      overtimeError: error,
      overtimeValidating: isValidating,
      overtimeEmpty: !data?.overtime,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetOvertimeByReviewer(employeeId) {
  const URL = `${endpoints.overtime.review.list}&${employeeId}`;

  const fetcherWithReviewer = async (...args) => fetchOvertimesByReviewer(employeeId, ...args);

  const { data, error, isValidating } = useSWR(URL, fetcherWithReviewer, optionSWRFocusFalse);

  const memoizedValue = useMemo(
    () => ({
      overtimes: data?.overtimes || [],
      isLoading: !error && !data,
      overtimesError: error,
      overtimesValidating: isValidating,
      overtimesEmpty: data?.overtimes && !data?.overtimes.length,
    }),
    [data, error, isValidating]
  );

  return memoizedValue;
}

export function useGetHrsystemAttendanceByEmployeeId(year_month, employeeId) {
  // 用 year_month 和 employeeId 組成一個 URL 作為 SWR 的 key
  const URL = `hrsystem?year_month=${year_month}&employeeId=${employeeId}`;

  const fetcherWithIds = async (...args) =>
    fetchHrsystemByEmployee(year_month, employeeId, ...args);

  const { data, error, isValidating, isLoading } = useSWR(URL, fetcherWithIds);

  const memoizedValue = useMemo(
    () => ({
      targetAttendance: data?.targetAttendance || null,
      last_update: data?.last_update || null,
      isLoading,
      error,
      isValidating,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}

export function useGetHrsystemAttendance(year_month, employee_ids = null) {
  // 用 year_month 和 employeeId 組成一個 URL 作為 SWR 的 key
  const URL = employee_ids
    ? `hrsystem?year_month=${year_month}&employee_ids=${employee_ids.join(',')}`
    : `hrsystem?year_month=${year_month}`;

  const fetcherWithIds = async (...args) =>
    fetchHrsystemAttendance(year_month, employee_ids, ...args);

  const { data, error, isValidating, isLoading } = useSWR(URL, fetcherWithIds);

  const memoizedValue = useMemo(
    () => ({
      attendance_data: data?.attendance_data || null,
      last_update: data?.last_update || null,
      isLoading,
      error,
      isValidating,
    }),
    [data, error, isValidating, isLoading]
  );

  const refresh = () => {
    mutate(URL);
  };

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}


export function useGetTodayClockRecords(today) {

  const URL = `clock-records/${today}`; // 假設您將此 URL 用於 SWR 作為識別符

  const fetcherClockRecord = async () => fetchClockRecordsByDay(today);

  const { data, error, isValidating } = useSWR(URL, fetcherClockRecord, optionSWRAllFalse);

  const memoizedValue = useMemo(
    () => ({
      todayClockRecords: data || [],
      isLoading: !error && !data,
      isError: error,
      isValidating,
      isEmpty: data && !data?.length,
    }),
    [data, error, isValidating]
  );

  const refresh = useCallback(() => {
    mutate(URL);
  }, [URL]);

  return {
    ...memoizedValue,
    refresh, // 添加 refresh 方法
  };
}