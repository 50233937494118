import { palette as themePalette } from 'src/theme/palette';

// ----------------------------------------------------------------------

const palette = themePalette('light');

export const CALENDAR_COLOR_OPTIONS = [
  palette.success.main,
  palette.warning.main,
  palette.error.darker,
];
