import { format, getTime, formatDistanceToNow } from 'date-fns';
import { zhTW } from 'date-fns/locale';
import { Timestamp } from 'firebase/firestore';

// ----------------------------------------------------------------------

export function fDate(date, newFormat, user_locale) {
  const fm = newFormat || 'yyyyMMdd';

  return date ? format(new Date(date), fm, {
    locale: user_locale,
  }) : '';
}

// export function fDate(date, newFormat) {
//   const fm = newFormat || 'dd MMM yyyy';

//   return date ? format(new Date(date), fm) : '';
// }

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

// export function fTimestamp(date) {
//   return date ? getTime(new Date(date)) : '';
// }

// export function fTimestamp(date) {
//   if (date instanceof Timestamp) {
//     return date.toDate().getTime();
//   }
//   return date ? new Date(date).getTime() : 0;
// }

// 
export function fTimestamp(date, isEndDate = false) {
  if (date instanceof Timestamp) {
    return date.toDate().getTime();
  }
  
  if (isEndDate) {
    const endDateTime = new Date(date);
    endDateTime.setHours(23, 59, 59, 999);
    return endDateTime.getTime();
  }

  return date ? new Date(date).getTime() : 0;
}

export function fWorkTimestamp(date, isEndDate = false) {
  if (date instanceof Timestamp) {
    return date.toDate().getTime(); // 如果是 Firestore 的 Timestamp 對象，直接轉為 JavaScript Date 對象並獲取時間戳
  }
  
  const workDateTime = new Date(date);
  
  if (isEndDate) {
    workDateTime.setHours(17, 30, 0, 0);
  } else {
    workDateTime.setHours(8, 30, 0, 0);
  }

  return workDateTime.getTime(); // 返回時間戳
}


export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
