import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { useState, useCallback, useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
// utils
import { fData } from 'src/utils/format-number';
import { fDateTime } from 'src/utils/format-time';
// hooks
import { useAuthContext } from 'src/auth/hooks';
import { useBoolean } from 'src/hooks/use-boolean';
import { useGetTodayClockRecords } from 'src/api/attendance';
// locales
import { useLocales } from 'src/locales';

// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';

export default function AppClockRecordsDrawer({ open, onClose, ...other }) {

  const { t } = useLocales();
  const { user, general } = useAuthContext();

  const today = new Date();
  today.setHours(today.getHours() + 8); // 加上8小時以轉換為台灣時區
  const localDate = today.toISOString().split('T')[0];

  const { todayClockRecords, isLoading, refresh } = useGetTodayClockRecords(localDate);
  console.log('todayClockRecords', todayClockRecords);

  const [enhancedClockRecords, setEnhancedClockRecords] = useState([]);

  const loadedData = useBoolean();

  useEffect(() => {
    if (!isLoading && todayClockRecords && general.employees) {
      // console.log('todayClockRecords useEffect');
      const tempRecords = {};
      todayClockRecords.forEach((record) => {
        const employeeInfo = general.employees.find(
          (employee) => employee.id === record.employeeId
        );
        if (employeeInfo && employeeInfo.attendanceTime && record.clockTime) {
          const actualDate = record.clockTime.toDate();
          const actualTimeInSeconds =
            actualDate.getHours() * 3600 + actualDate.getMinutes() * 60 + actualDate.getSeconds();

          const [expectedHours, expectedMinutes] = employeeInfo.attendanceTime.clockIn
            .split(':')
            .map(Number);
          const expectedTimeInSeconds = expectedHours * 3600 + expectedMinutes * 60;
          const earlySeconds = expectedTimeInSeconds - actualTimeInSeconds;

          const enhancedRecord = {
            ...record,
            employeeName: employeeInfo.name,
            photoURL: employeeInfo.photoURL,
            attendanceTime: employeeInfo.attendanceTime,
            earlySeconds,
            seconds: actualTimeInSeconds, // 保存原始的秒數用於比較
          };

          if (!tempRecords[record.employeeId]) {
            tempRecords[record.employeeId] = { earliest: enhancedRecord, latest: enhancedRecord };
          } else {
            // 更新最早打卡時間
            if (tempRecords[record.employeeId].earliest.seconds > enhancedRecord.seconds) {
              tempRecords[record.employeeId].earliest = enhancedRecord;
            }
            // 更新最後打卡時間
            if (tempRecords[record.employeeId].latest.seconds < enhancedRecord.seconds) {
              tempRecords[record.employeeId].latest = enhancedRecord;
            }
          }
        }
      });

      // 篩選出需要保留的記錄
      const clockRecords = [];
      Object.values(tempRecords).forEach(({ earliest, latest }) => {
        clockRecords.push(earliest);
        // 檢查最後打卡時間是否晚於最早打卡時間30分鐘
        if (latest.seconds > earliest.seconds + 1800 && latest !== earliest) {
          clockRecords.push(latest);
        }
      });

      setEnhancedClockRecords(clockRecords);
    }
  }, [todayClockRecords, isLoading, general.employees]);

  useEffect(() => {
    // 只有在 open 從 false 變成 true，且 loadedData.value 為 false 時，才執行 refresh
    if (open && !loadedData.value) {
      console.log('open useEffect');
      refresh(); // 觸發刷新數據的函數
      loadedData.onTrue(); // 將 loadedData 設為 true，阻止再次執行 refresh 直到重置
    }
    // 當 open 為 false 時，重置 loadedData 為 false
    if (!open) {
      loadedData.onFalse();
    }
  }, [open, refresh, loadedData]);

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        slotProps={{
          backdrop: { invisible: true },
        }}
        PaperProps={{
          sx: { width: 320 },
        }}
        {...other}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 1.5 }}
          >
            <Typography variant="h6">{t('clock_today')}</Typography>
            <Typography variant="subtitle2">{t('date')}：{localDate} </Typography>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed', borderColor: 'common.grey' }} />

          <Stack spacing={2.5} sx={{ p: 2.5 }}>
            {orderBy(enhancedClockRecords, ['clockTime'], ['asc']).map((record, index) => (
              <ClockRecordItem
                key={`${record.employeeId}-${index}`}
                record={record}
                index={index}
              />
            ))}
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}

AppClockRecordsDrawer.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

function ClockRecordItem({ record, index }) {
  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography variant="subtitle2">{index + 1}.</Typography>

      <Avatar alt={record.employeeName} src={record?.photoURL} />

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="subtitle2">{record.employeeName}</Typography>

        <Typography
          variant="caption"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
          }}
        >
          打卡時間：{record?.clockTime.toDate().toLocaleTimeString('zh-TW', {})}
        </Typography>
      </Box>
    </Stack>
  );
}

ClockRecordItem.propTypes = {
  record: PropTypes.object,
  index: PropTypes.number,
};
