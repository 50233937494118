import PropTypes from 'prop-types';
import { useEffect, useReducer, useCallback, useMemo } from 'react';

import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signOut,
  signInWithPopup,
  onAuthStateChanged,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  getDocs,
  updateDoc
} from 'firebase/firestore';
// config
import { FIREBASE_API } from 'src/config-global';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API);

const AUTH = getAuth(firebaseApp);

const DB = getFirestore(firebaseApp);

// ----------------------------------------------------------------------

const initialState = {
  user: null,
  approved: false,
  general: null,
  loading: true,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIAL':
      return {
        loading: false,
        approved: action.payload.approved,
        general: action.payload.general,
        user: action.payload.user,
      };

    case 'NOTAPPROVED':
      return {
        loading: false,
        approved: false,
        user: action.payload.user,
      };
    default:
      return state;
  }
};

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  AUTH.languageCode = 'zh-TW';

  const [state, dispatch] = useReducer(reducer, initialState);

  const fetchGeneralSettings = async () => {
    const docRef = doc(DB, '_settings', 'general');
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const rawData = docSnap.data();
      const parsedData = {};

      Object.keys(rawData).forEach((key) => {
        const value = rawData[key];
        // 檢查每個欄位是否是 JSON 字符串
        if (typeof value === 'string') {
          try {
            parsedData[key] = JSON.parse(value);
          } catch (error) {
            // 如果解析失敗，保留原始值
            parsedData[key] = value;
          }
        } else {
          // 如果不是字符串，直接保留原始值
          parsedData[key] = value;
        }
      });

      return parsedData;
    }

    return null;
  };

  const initialize = useCallback(() => {
    const dispatchInitialNull = () => {
      dispatch({
        type: 'INITIAL',
        payload: {
          approved: true,
          user: null,
          general: null,
        },
      });
    };

    const dispatchInitialNotApproved = (user) => {
      dispatch({
        type: 'NOTAPPROVED',
        payload: {
          approved: false,
          user: { ...user },
          general: null,
        },
      });
    };

    const dispatchInitialUser = (user, profile, generalSettings) => {
      dispatch({
        type: 'INITIAL',
        payload: {
          approved: true,
          user: {
            ...user,
            ...profile,
            // uid: user.uid,
            // accessToken: user.accessToken,
            displayName: profile.lastName + profile.firstName,
          },
          general: generalSettings, // 將 generalSettings 存儲在 payload 的 settings 字段中
        },
      });
    };

    try {
      onAuthStateChanged(AUTH, async (user) => {

        if (!user) {
          console.error('User is not get.');
          return dispatchInitialNull();
        }

        if (!user.emailVerified) {
          console.error('User is not emailVerified.');
          return dispatchInitialNull();
        }

        // const token = await user.getIdToken(); // 獲取 token
        const { photoURL, uid, accessToken } = user; // 獲取 photoURL

        const q = query(collection(DB, 'employees'), where('email', '==', user.email));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          console.error('User not approved!');
          return dispatchInitialNotApproved(user);
        }

        const profile = querySnapshot.docs[0].data(); // 取第一筆資料
        const userDocRef = querySnapshot.docs[0].ref;  // 獲取查詢的文件參考
        // profile.token = token;  // 將 token 加入到 profile 物件中
        profile.accessToken = accessToken;
        profile.photoURL = photoURL;  // 將 photoURL 加入到 profile 物件中

        if (!profile.isActive) {
          return dispatchInitialNull();
        }

        // TODO:這邊之後拿掉，到時手動用python更新，然後可以讓使用者自訂 photoURL
        await updateDoc(userDocRef, {
          photoURL
        });  // 更新文件

        // const authDocRef = doc(DB, 'auth', user.email);
        // await updateDoc(authDocRef, {
        //   // token,
        //   uid,
        //   accessToken,
        //   photoURL
        // });  // 使用 merge 真來避免覆蓋已有的資料

        const generalSettings = await fetchGeneralSettings(); // 讀取 _settings/general 文檔
        // console.log(generalSettings);

        return dispatchInitialUser(user, profile, generalSettings); // 將 generalSettings 添加到 payload
      });
    } catch (error) {
      console.error(error);
      return dispatchInitialNull(); // 確保每個路徑都有返回值
    }

    return null; // 確保函數在所有路徑上都有返回值
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email, password) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);

  const loginWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  const loginWithFacebook = useCallback(async () => {
    const provider = new FacebookAuthProvider();

    await signInWithPopup(AUTH, provider);
  }, []);

  // REGISTER
  const register = useCallback(async (email, password, firstName, lastName) => {
    const newUser = await createUserWithEmailAndPassword(AUTH, email, password);

    await sendEmailVerification(newUser.user);

    const userProfile = doc(collection(DB, 'users_apply'), newUser.user?.uid);

    await setDoc(userProfile, {
      uid: newUser.user?.uid,
      email,
      lastName: `${lastName}`,
      firstName: `${firstName}`,
    });
  }, []);

  // LOGOUT
  const logout = useCallback(async () => {
    await signOut(AUTH);
  }, []);

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email) => {
    await sendPasswordResetEmail(AUTH, email);
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user?.emailVerified ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      general: state.general,
      approved: state.approved,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithFacebook,
    }),
    [
      status,
      state.user,
      state.approved,
      state.general,
      //
      login,
      logout,
      register,
      forgotPassword,
      loginWithGoogle,
      loginWithFacebook,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

AuthProvider.propTypes = {
  children: PropTypes.node,
};
